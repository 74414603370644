import React, { useEffect } from 'react';
import useScript from './useScript';
const { REACT_APP_ALTENAR_URL } = process.env;
import PropTypes from 'prop-types';
import Loader from 'components/Loader';

export const AltenarSDK = ({ children }) => {

  const status = useScript(REACT_APP_ALTENAR_URL);
  useEffect(() => {
    if (status !== 'ready') return;
    if (!window?.altenarWSDK) {
      console.log('Unable to load altenarSDK from window');
      return;
    }

    window.altenarWSDK.init({
      integration: 'palpitenarede',
      culture: 'pt-BR'
    });
  }, [status]);


  return status == ('ready' || 'error') ? children : <Loader m={2} height="auto" />;
};

AltenarSDK.propTypes = {
  children: PropTypes.any
};
