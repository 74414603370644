/*eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { useDeviceInfo } from 'hooks/useDeviceInfo';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Button as TwButton } from 'components/Tailwind/button/button';
import { FormSignup } from './formSignup';

export const ModalSignup = () => {
  const [bonus, setBonus] = useState('');
  const { media, device } = useDeviceInfo();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [cancelSignup, setCancelSignup] = useState(false);

  useEffect(() => {
    const url = new URL(window?.location?.href);
    const searchParams = url.searchParams;
    const campaignName = searchParams.get('campaign_name');

    setBonus(campaignName);
    // eslint-disable-next-line
  }, []);

  if (media === 'mobile' && device === 'iPhone') {
    window.scrollTo(0, 0);
  }

  const handleCloseDialog = () => {
    setIsOpen(false);
    setCancelSignup(false);
  };

  useEffect(() => {
    const handleOpenModal = () => {
      setIsOpen(true);
    };

    document.addEventListener('openModalSignup', handleOpenModal, false);

    return () => {
      document.removeEventListener('openModalSignup', handleOpenModal);
    };
  }, []);

  return (
    <>
      <Button
        id='app-bar-register'
        variant='contained'
        color='primary'
        size='small'
        onClick={() => setIsOpen(true)}
      >
        <span>{t('appBar.signup')}</span>
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setCancelSignup(true)}
        className='tw-relative tw-z-50'
      >
        <div
          className='tw-fixed tw-inset-0 tw-bg-gray-700/70'
          aria-hidden='true'
        />
        <div className='tw-fixed tw-inset-0 tw-flex tw-w-screen tw-items-center tw-justify-center'>
          <Dialog.Panel className='tw-bg-[#1A202A] tw-w-[90vw] md:tw-w-[450px] tw-rounded-lg tw-p-4 tw-max-w-md tw-max-h-[80%] lg:tw-max-h-fit tw-overflow-y-auto tw-overflow-x-hidden tw-mt-12 lg:tw-mt-0'>
            <Dialog.Title className='tw-flex tw-justify-end tw-w-full' as='div'>
              {!cancelSignup && (
                <button onClick={() => setCancelSignup(true)}>
                  <XMarkIcon className='tw-w-7 tw-h-7 tw-text-gray-200 hover:tw-text-gray-400' />
                </button>
              )}
            </Dialog.Title>
            <Dialog.Description as='div'>
              {cancelSignup ? (
                <div className='tw-flex tw-gap-6 tw-flex-col tw-justify-center tw-py-0 md:tw-py-4'>
                  <p className='tw-text-2xl tw-font-bold tw-text-center'>
                    Tem certeza que deseja cancelar seu registro?
                  </p>
                  <div className='tw-mt-6 tw-flex tw-flex-col tw-gap-1'>
                    <TwButton onClick={() => setCancelSignup(false)} size='lg'>
                      Continuar
                    </TwButton>
                    <TwButton
                      color='secondary'
                      onClick={handleCloseDialog}
                      size='lg'
                    >
                      Cancelar registro
                    </TwButton>
                  </div>
                </div>
              ) : (
                <FormSignup closeModal={setIsOpen} />
              )}
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
