/*eslint-disable */
import React, { useState } from 'react';
import logo from '../../assets/logo_palpite.png';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from 'hooks/useAuth';
import { useParams, useHistory } from 'react-router-dom';
import { useGovCpf } from 'hooks/useGovCPF';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import eye from 'assets/icons/eye.png';
import eyeCross from 'assets/icons/eye-cross.png';
import { InputMask } from 'components/Tailwind/Input/inputMask';
import { ProviderSite } from 'hooks';
import { Loading } from 'components/Tailwind/loading/loading';
import { PhoneFormat } from 'components/InputFormat';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export const FormSignup = ({ closeModal }) => {
  const {
    signUp,
    loading: { signUp: isLoading },
    error: { signUp: errorMessage },
  } = useAuth();
  const { code } = useParams();
  const { searchCPF } = useGovCpf();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+55');
  const [password, setPassword] = useState('');
  const [birthDate, setBirthDate] = useState(null);
  const [typeField, setTypeField] = useState('password');
  const [customMessage, setCustomMessage] = useState('');
  const [government_id, setGovernment_id] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [cpfValidation, setCpfValidation] = useState('');
  const [validateCpf, setValidateCpf] = useState('');
  const history = useHistory();
  const [validation, setValidation] = useState({});
  const [cpfLoading, setCpfLoading] = useState(false);

  const classes = makeStyles(theme => ({
    root: {
      '& label': {
        transform: 'translate(12px, 50%) scale(1)',
        color: '#878787',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(12px, 4px) scale(0.75)',
        },
      },
      '& p.MuiFormHelperText-root': {
        color: '#FFF',
        marginTop: '2px',
      },
      '& input': {
        height: 'auto',
        padding: '16px 12px 8px 12px !important',
        '&:-webkit-autofill': {
          WebkitBoxShadow: 'none',
          borderRadius: '6px !important',
          WebkitTextFillColor: '#131523 !important',
        },
      },
      '& input.Mui-disabled': {
        color: '#9ca3af !important',
      },
      '& div': {
        '&.MuiInputAdornment-positionEnd': {
          marginLeft: '-60px',
        },
        '& button': {
          '&.MuiIconButton-root': {
            color: '#131523',
          },
        },
      },
      '& div.inputIcon': {
        '& button': {
          '& span': {
            maxWidth: '20px',
            display: 'block',
          },
        },
      },
    },
  }))();

  const changeType = async () => {
    if (typeField == 'password') {
      setTypeField('text');
    } else {
      setTypeField('password');
    }
  };

  const validCpf =
    government_id.length === 14 && !validateCpf && !cpfValidation;

  const handleCpf = async event => {
    if (event.target.value.length < 14) {
      setCpfValidation('');
      setName('');
      setBirthDate(null);
      setValidateCpf('');
    }

    if (event.target.value.length == 14) {
      setCpfLoading(true);
      let url = '/validateExistingAccount?';
      if (event.target.value) url += `governmentId=${event.target.value}`;
      try {
        await ProviderSite.get(url);
      } catch (e) {
        setValidateCpf(e.response?.data?.message);
        setCpfLoading(false);
        return;
      }

      setValidateCpf('');

      const response = await searchCPF(event.target.value);
      if (!response || response == 400 || response == 404) {
        setCpfValidation('Cpf inválido');
        setName('');
        setBirthDate(null);
        setCpfLoading(false);
        return;
      }
      if (response == 401) {
        setCpfValidation('Token expirado, contate o suporte');
        setName('');
        setBirthDate(null);
        setCpfLoading(false);
        return;
      }
      const { name, birthdate } = response;

      setName(name);
      setBirthDate(moment(new Date(birthdate), 'DDMMYYYY'));
      setCpfValidation('');
      setCpfLoading(false);
    }
  };

  const handleSubmit = evt => {
    evt.preventDefault();

    let validateField = {};
    setCustomMessage(null);
    setValidation({});

    if (!government_id) {
      validateField.government_id = 'Cpf obrigatório';
      setValidation(prev => {
        return { ...prev, government_id: 'Cpf obrigatório' };
      });
    }
    if (!name) {
      validateField.name = 'Nome obrigatório';
      setValidation(prev => {
        return { ...prev, name: 'Nome obrigatório' };
      });
    }
    if (!birthDate) {
      validateField.birthDate = 'Data de nascimento obrigatória';
      setValidation(prev => {
        return { ...prev, birthDate: 'Data de nascimento obrigatória' };
      });
    }
    if (!userName) {
      validateField.userName = 'Nome de usuário obrigatório';
      setValidation(prev => {
        return { ...prev, userName: 'Nome de usuário obrigatório' };
      });
    }
    if (phoneNumber.length < 13) {
      validateField.phoneNumber = 'Celular inválido';
      setValidation(prev => {
        return { ...prev, phoneNumber: 'Celular inválido' };
      });
    }
    if (password.length < 6) {
      validateField.password = 'Senha inválida';
      setValidation(prev => {
        return { ...prev, password: 'Senha inválida' };
      });
    }
    if (!email) {
      validateField.email = 'Email obrigatório';
      setValidation(prev => {
        return { ...prev, email: 'Email obrigatório' };
      });
    }
    if (!email.includes('@') || !email.includes('.com')) {
      validateField.email = 'Email inválido';
      setValidation(prev => {
        return { ...prev, email: 'Email inválido' };
      });
    }

    if (moment().subtract(18, 'years').isBefore(birthDate)) {
      validateField.birthDate = 'Data é menor que 18 anos.';
      setCustomMessage('Data é menor que 18 anos.');
    }

    if (!Object.keys(validateField).length) {
      signUp({
        name,
        phoneNumber,
        password,
        government_id,
        birthDate: birthDate.format('YYYY-MM-DD'),
        promoCode: code,
        userName,
        email,
      });
    }
  };

  const handleSendToSignin = () => {
    if (closeModal) closeModal(false);
    history.push('/entrar');
  };

  return (
    <div className='tw-mx-auto tw-max-w-[90%] md:tw-max-w-[80%]'>
      <img src={logo} className='tw-h-24 tw-mx-auto tw-mb-4' />
      <form
        className={`${classes.root} tw-space-y-2`}
        onSubmit={handleSubmit}
        autoComplete='none'
      >
        <>
          <div className='tw-relative'>
            <TextField
              variant='filled'
              fullWidth
              value={email}
              onChange={evt => setEmail(evt.target.value)}
              disabled={isLoading}
              id='email'
              label='E-mail'
              name='email'
              autoComplete='off'
            />
            {validation?.email && (
              <div className='tw-absolute tw-right-0 tw-bottom-0'>
                <CustomError message={validation.email} />
              </div>
            )}
          </div>
          <div className='inputIcon tw-relative'>
            <TextField
              variant='filled'
              fullWidth
              value={password}
              onChange={evt => setPassword(evt.target.value)}
              disabled={isLoading}
              name='password'
              label='Senha'
              type={typeField}
              id='password'
              autoComplete='off'
            />
            {validation?.password && (
              <div className='tw-absolute tw-right-0 tw-bottom-0'>
                <CustomError message={validation.password} />
              </div>
            )}
            <Button
              onClick={changeType}
              style={{
                position: 'absolute',
                right: 4,
                top: 8,
              }}
              className='!tw-min-w-5'
            >
              {typeField == 'text' ? (
                <img src={eye} style={{ width: 20 }} />
              ) : (
                <img src={eyeCross} style={{ width: 20 }} />
              )}
            </Button>
          </div>
          <div className='tw-relative'>
            <InputMask
              mask='000.000.000-00'
              label='CPF'
              value={government_id}
              onAccept={setGovernment_id}
              onChange={handleCpf}
              name='government_id'
              disabled={cpfLoading}
            />
            {cpfLoading && (
              <div className='tw-absolute tw-right-4 tw-top-[10px] tw-w-6 tw-h-6'>
                <Loading className='' />
              </div>
            )}
            {validation?.government_id && (
              <div className='tw-absolute tw-right-0 tw-bottom-0'>
                <CustomError message={validation.government_id} />
              </div>
            )}
            {cpfValidation && (
              <div className='tw-absolute tw-right-0 tw-bottom-0'>
                <CustomError message={cpfValidation} />
              </div>
            )}
            {!!validateCpf && (
              <div className='tw-absolute tw-right-0 tw-bottom-0'>
                <CustomError message={validateCpf} />
              </div>
            )}
          </div>
          <div
            className={`${
              validCpf && !cpfLoading ? 'tw-block' : 'tw-hidden'
            } tw-relative`}
          >
            <TextField
              variant='filled'
              fullWidth
              value={name}
              onChange={evt => setName(evt.target.value)}
              disabled
              id='name'
              label='Nome'
              name='name'
              autoComplete='off'
            />
            {validation?.name && (
              <div className='tw-absolute tw-right-0 tw-bottom-0'>
                <CustomError message={validation.name} />
              </div>
            )}
          </div>
          <div className='tw-relative'>
            <TextField
              variant='filled'
              fullWidth
              value={userName}
              onChange={evt => setUserName(evt.target.value)}
              disabled={isLoading}
              id='userName'
              label='Nome do usuário'
              name='userName'
              autoComplete='off'
            />
            {validation?.userName && (
              <div className='tw-absolute tw-right-0 tw-bottom-0'>
                <CustomError message={validation.userName} />
              </div>
            )}
          </div>
          <div className='tw-relative'>
            <TextField
              variant='filled'
              fullWidth
              value={phoneNumber}
              onChange={setPhoneNumber}
              disabled={isLoading}
              id='phoneNumber'
              label='Celular'
              name='phoneNumber'
              InputProps={{ inputComponent: PhoneFormat }}
              autoComplete='off'
            />
            {validation?.phoneNumber && (
              <div className='tw-absolute tw-right-0 tw-bottom-0'>
                <CustomError message={validation.phoneNumber} />
              </div>
            )}
          </div>
        </>

        <div className='tw-mt-5 tw-space-y-2'>
          {errorMessage && customMessage == null ? (
            <Alert severity='error'>{errorMessage}</Alert>
          ) : null}
          {!!customMessage && <Alert severity='error'>{customMessage}</Alert>}
          <p className='tw-text-[10px] tw-text-center'>
            Ao se registrar{' '}
            <Link href='/termos-de-servico' className='tw-text-primary'>
              você concorda com nossos termos e condições
            </Link>
          </p>
          <Button
            type='submit'
            id='signup-register'
            fullWidth
            variant='contained'
            color='primary'
            loading={isLoading}
            mt={3}
            mb={2}
          >
            Cadastrar
          </Button>
        </div>
      </form>

      <div className='tw-my-3'>
        <Typography>
          Já tem conta?{' '}
          <button
            type='button'
            onClick={handleSendToSignin}
            className='tw-text-primary'
          >
            Entrar
          </button>
        </Typography>
      </div>
    </div>
  );
};

FormSignup.propTypes = {
  closeModal: PropTypes.func,
};

const CustomError = ({ message }) => {
  return (
    <p className='tw-text-white tw-bg-danger-500 tw-px-1.5 md:tw-px-2 tw-py-0.5 tw-text-[8px] md:tw-text-[10px] tw-rounded-sm'>
      {message}
    </p>
  );
};

CustomError.propTypes = {
  message: PropTypes.string,
};
