/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Button } from 'components/Tailwind/button/button';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { DepositForm } from './depositForm';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import logoPix from '../../../../assets/icons/pix.png';
import clsx from 'clsx';

export const ModalDeposit = ({
  orientationMobile,
  mediaScreen,
  withTag,
  shape,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleOpenModalDeposit = () => {
      setIsOpen(true);
    };

    document.addEventListener(
      'openModalDeposit',
      handleOpenModalDeposit,
      false
    );

    return () => {
      document.removeEventListener('openModalDeposit', handleOpenModalDeposit);
    };
  }, []);

  return (
    <>
      <Button
        className={clsx(
          '!tw-py-1 !tw-px-4 tw-text-xs tw-relative',
          orientationMobile
            ? 'md:!tw-py-1 md:!tw-px-1.5 md:text-xs'
            : 'md:!tw-py-1.5 md:!tw-px-5 md:tw-text-base'
        )}
        onClick={() => setIsOpen(true)}
        shape={shape}
      >
        {withTag && (
          <span className='tw-bg-white tw-text-black tw-text-[8px] md:tw-text-xs tw-rounded-full tw-px-1.5 tw-flex tw-justify-center tw-items-center tw-gap-0.5 tw-absolute -tw-top-2 tw-right-0 tw-h-3 md:tw-h-4'>
            <img src={logoPix} className='tw-w-2 tw-h-2 md:tw-w-3 md:tw-h-3' />
            Pix
          </span>
        )}
        <span>{t('money.deposit')}</span>
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className='tw-relative tw-z-50'
      >
        <div
          className='tw-fixed tw-inset-0 tw-bg-gray-700/70'
          aria-hidden='true'
        />
        <div className='tw-fixed tw-inset-0 tw-flex tw-w-screen tw-items-center tw-justify-center'>
          <Dialog.Panel className='tw-bg-[#1A202A] tw-rounded-lg tw-p-4 tw-max-w-sm lg:tw-max-w-xl tw-max-h-[466px] lg:tw-max-h-fit tw-overflow-y-auto tw-overflow-x-hidden tw-mt-12 lg:tw-mt-0'>
            <Dialog.Title className='tw-flex tw-justify-end tw-w-full' as='div'>
              <button onClick={() => setIsOpen(false)}>
                <XMarkIcon className='tw-w-7 tw-h-7 tw-text-gray-200 hover:tw-text-gray-400' />
              </button>
            </Dialog.Title>
            <Dialog.Description as='div'>
              <DepositForm mediaScreen={mediaScreen} />
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

ModalDeposit.propTypes = {
  orientationMobile: PropTypes.bool,
  mediaScreen: PropTypes.string,
  user: PropTypes.object,
  withTag: PropTypes.bool,
  shape: PropTypes.string,
};
