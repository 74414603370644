import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { AuthProvider } from 'hooks/useAuth';
import { ConfirmationProvider } from 'hooks/useConfirmation';
import { AppBarProvider } from 'hooks/useAppBar';
import { SocketProvider } from 'hooks/useSocket';
import { ConfettiProvider } from 'hooks/useConfetti';
import { IsOnlineProvider } from 'hooks/useIsOnline';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import theme from 'theme/dealer';
import Pages from 'pages';
import TagManager from 'react-gtm-module';
import { AltenarSDK } from 'hooks/useAltenar';

const tagManagerArgs = {
  gtmId: 'GTM-KZ4WP32'
};

TagManager.initialize(tagManagerArgs);

moment.locale('pt-br');

const RootApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IsOnlineProvider>
          <AltenarSDK>
            <Router>
              <SocketProvider>
                <ConfirmationProvider>
                  <AuthProvider>
                    <AppBarProvider>
                      <ConfettiProvider>
                        <Pages />
                      </ConfettiProvider>
                    </AppBarProvider>
                  </AuthProvider>
                </ConfirmationProvider>
              </SocketProvider>
            </Router>
          </AltenarSDK>
        </IsOnlineProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default RootApp;
