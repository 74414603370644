import React from 'react';
import { InputBase } from './inputBase';
import { IMaskMixin } from 'react-imask';
import PropTypes from 'prop-types';

const InputPhoneMix = IMaskMixin(({ inputRef, ...props }) => {
  return <InputBase {...props} ref={inputRef} />;
});

export const InputMask = props => {
  const handleChange = value => {
    if (props.onAccept) props.onAccept(value);
  };

  const handleBlur = value => {
    if (props.onBlur) props.onBlur(value);
  };

  const blocks = {
    any: {
      mask: /(.*?)/
    }
  };

  return (
    <InputPhoneMix
      {...props}
      onAccept={handleChange}
      mask={props?.mask}
      blocks={blocks}
      onBlur={handleBlur}
    />
  );
};

InputPhoneMix.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  valueToLabel: PropTypes.string
};

InputMask.propTypes = {
  mask: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  error: PropTypes.string,
  value: PropTypes.string,
  onAccept: PropTypes.func,
  onBlur: PropTypes.func
};
