/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from 'components/Button';
import Message from 'components/Wallet/Message';
import { toCurrency } from 'helpers';
import useWallet from 'hooks/useWallet';
import useAuth from 'hooks/useAuth';
import useSocket from 'hooks/useSocket';
import { deposits_withdrawals } from 'pages/Wallet/routes';
import QrCodeComponent from 'components/Wallet/QRCode';
import CurrencyInput from 'components/CurrencyInput';
import TextField from '@material-ui/core/TextField';
// import { BonusForm } from 'components/Wallet/DepositForm/depositComponents/inputBonus';
import Provider from 'hooks';
import CpfCnpjComponent from 'components/InputFormat/cpfCnpj';
import { Button as TwButton } from 'components/Tailwind/button/button';
import './DepositCss.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const MIN_AMOUNT = 500;
const MAX_AMOUNT = 1000000;

export const DepositForm = ({ mediaScreen }) => {
  const { t } = useTranslation();
  const [payload, setPayload] = useState({ type: 'pix', bonus: '' });
  const [initial, setInitial] = useState(false);
  const [pixMessage, setPixMessage] = useState(false);
  const [rejectedMessage, setRejectedMessage] = useState(false);
  const [confirmedMessage, setConfirmedMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { chargePix } = useWallet();
  const { currentUser } = useAuth();
  const listen = useSocket();
  const [getQRCode, setGetQRCode] = useState();
  const [amount, setAmount] = useState(0);
  const [sicoob, setSicoob] = useState({});
  const [data, setData] = useState({
    name: '',
    document: '',
    bonus: '',
  });
  // const [bonusIsValid, setBonusIsValid] = useState(false);

  const hint = useMemo(() => {
    let message = '';
    if (MIN_AMOUNT) {
      message += `${t('min')} ${toCurrency(MIN_AMOUNT)}`;
      if (MAX_AMOUNT) {
        message += ' | ';
      }
    }
    if (MAX_AMOUNT) {
      message += `${t('max')}: ${toCurrency(MAX_AMOUNT)}`;
    }
    return message;
  }, [t]);

  const isValid =
    data?.name?.length > 5 &&
    data?.document?.length >= 11 &&
    // data?.bonus &&
    // bonusIsValid &&
    amount >= MIN_AMOUNT &&
    amount <= MAX_AMOUNT;

  const handleFocus = () => {
    if (amount) {
      setAmount(null);
    }
  };

  const handleChange = (evt, prop) => {
    const value = evt?.target ? evt.target.value?.trimStart() : evt;
    if (value.startsWith('-')) return;
    setData(prev => ({ ...prev, [prop]: value }));
    setPayload(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [prop]: value,
      },
    }));
  };

  const handleSetAmount = amount => {
    setAmount(amount);
    setPayload(prev => ({ ...prev, amount }));
  };

  const handleAvailablePrice = amount => {
    setAmount(amount);
    setPayload(prev => ({ ...prev, amount }));
  };

  const setSelectedBonus = bonus => {
    const formatBonus = bonus === 'withoutPromo' ? '' : bonus;
    setData(prev => ({ ...prev, bonus }));
    setPayload(prev => ({
      ...prev,
      data: { ...prev.data, bonus: formatBonus },
    }));
  };

  useEffect(() => {
    if (sicoob.qrcode && sicoob.status == 'Pending') {
      setGetQRCode(sicoob.qrcode);
      setInitial(false);
      setPixMessage(true);
    }

    if (sicoob.status == 'Error') {
      setPixMessage(false);
      setRejectedMessage(true);
    }
  }, [sicoob]);

  useEffect(() => {
    return listen('azStatusDeposit', (eventName, status) => {
      const socketUserId = parseInt(eventName.split('/')[1]);

      if (typeof status == 'object' && socketUserId == currentUser.id) {
        setGetQRCode(status.QRCode);
        setInitial(false);
        setPixMessage(true);
      }
      if (status == 'ERROR' && socketUserId == currentUser.id) {
        setPixMessage(false);
        setRejectedMessage(true);
      }
      if (status == 'FINISHED' && socketUserId == currentUser.id) {
        setPixMessage(false);
        setConfirmedMessage(true);
      }
    });
    // eslint-disable-next-line
  }, [listen]);

  useEffect(() => {
    return listen('sicoobStatusCharge', (eventName, status) => {
      const socketUserId = parseInt(eventName.split('/')[1]);

      if (
        ['REMOVIDA_PELO_USUARIO_RECEBEDOR', 'REMOVIDA_PELO_PSP'].includes(
          status
        ) &&
        socketUserId == currentUser.id
      ) {
        setPixMessage(false);
        setRejectedMessage(true);
      }
      if (status == 'CONCLUIDA' && socketUserId == currentUser.id) {
        setPixMessage(false);
        setConfirmedMessage(true);
      }
    });
    // eslint-disable-next-line
  }, [listen]);

  useEffect(() => {
    const getBonusReq = async () => {
      const result = await Provider.post('/altenar/getBonusInfo', {
        walletId: currentUser.Wallet.id,
      });

      setData(() => ({
        name: currentUser?.name,
        document: currentUser?.government_id
          ?.replaceAll('.', '')
          .replace('-', ''),
        // bonus: result?.campaign_name.trim(),
      }));
      setPayload(prev => ({
        ...prev,
        data: {
          name: currentUser?.name,
          document: currentUser?.government_id
            ?.replace('-', '')
            .replaceAll('.', ''),
          // bonus: result?.campaign_name.trim(),
        },
      }));

      return result;
    };

    getBonusReq();
    // eslint-disable-next-line
  }, []);

  if (currentUser.availableToDeposit === false) {
    return (
      <Message message='Por favor, procurar um ponto de venda mais próximo.' />
    );
  }

  if (initial == true) {
    return <Message title='Aguarde enquanto sua solicitacao é analisada' />;
  }

  if (pixMessage == true) {
    return (
      <QrCodeComponent
        qrCodeSerial={getQRCode}
        amount={amount}
        mediaScreen={mediaScreen}
      />
    );
  }

  if (rejectedMessage == true) {
    return (
      <Message
        title='Pagamento recusado!'
        buttonName={'Voltar'}
        route={deposits_withdrawals}
      />
    );
  }

  if (confirmedMessage == true) {
    return (
      <Message
        title='Pagamento confirmado!'
        buttonName={'Voltar'}
        route={deposits_withdrawals}
      />
    );
  }

  const handleFinish = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await chargePix(payload);
      response?.vendor == 'sicoob' && setSicoob(response);
      setInitial(true);
    } catch (err) {
      setErrorMessage(err?.message);
    }
    setIsLoading(false);
  };

  const availablePrices = [3000, 5000, 10000, 15000];

  return (
    <div className='tw-p-2 tw-mb-3 tw-flex tw-flex-col tw-gap-6'>
      <h2 className='tw-font-semibold tw-text-lg'>{t('money.deposit')}</h2>
      <div className='tw-space-y-4'>
        <CurrencyInput
          variant='outlined'
          value={amount}
          onFocus={handleFocus}
          onChange={handleSetAmount}
          helperText={<span className='tw-text-gray-400'>{hint}</span>}
          fullWidth
        />
        {payload?.amount < MIN_AMOUNT && (
          <p className='tw-text-danger-700 tw-font-semibold tw-bg-danger-500/20 tw-rounded-lg tw-p-4 tw-flex tw-justify-center tw-align-center'>
            Valor mínimo de depósito R$ 5,00
          </p>
        )}
        {payload?.amount > MAX_AMOUNT && (
          <p className='tw-text-danger-700 tw-font-semibold tw-bg-danger-500/20 tw-rounded-lg tw-p-4 tw-flex tw-justify-center tw-align-center'>
            Valor máximo de depósito R$ 10.000,00
          </p>
        )}
        <div className='tw-flex tw-gap-5 tw-overflow-y-auto tw-pb-2 lg:tw-pb-0'>
          {availablePrices.map(price => (
            <TwButton key={price} onClick={() => handleAvailablePrice(price)}>
              <p>{toCurrency(price)}</p>
            </TwButton>
          ))}
        </div>
      </div>
      {(currentUser?.name?.length < 3 ||
        currentUser?.government_id?.length < 14 ||
        !currentUser?.government_id) && (
        <>
          <TextField
            variant='outlined'
            value={data.name}
            label={
              <label className='tw-text-gray-400'>
                {t('modalDeposit.name')}
              </label>
            }
            onChange={e => handleChange(e, 'name')}
            fullWidth
          />
          <TextField
            variant='outlined'
            label={
              <label className='tw-text-gray-400'>
                {t('modalDeposit.cpfCnpj')}
              </label>
            }
            onChange={e => handleChange(e, 'document')}
            InputProps={{ inputComponent: CpfCnpjComponent }}
            value={data.document}
            fullWidth
          />
        </>
      )}
      {/* <div>
        <h1 className='tw-font-medium tw-my-3'>
          {t('modalDeposit.promotion.title')}
        </h1>
        <BonusForm
          amount={amount}
          selectedBonus={data?.bonus}
          setSelectedBonus={setSelectedBonus}
          setBonusIsValid={setBonusIsValid}
        />
      </div> */}
      {!!errorMessage && (
        <Alert className='tw-mt-1' severity='error'>
          {errorMessage}
        </Alert>
      )}
      <div className='tw-flex tw-justify-center tw-justify-self-end'>
        <Button
          variant='contained'
          id='confirm-deposit'
          loading={isLoading}
          disabled={!isValid}
          color='primary'
          mt={2}
          onClick={handleFinish}
        >
          {t('generatePix')}
        </Button>
      </div>
    </div>
  );
};

DepositForm.propTypes = {
  mediaScreen: PropTypes.string,
};
