export default async function initOneSignal () {
  var OneSignal = window.OneSignal || [];
  OneSignal.push(function () {
    OneSignal.init({
      appId: '087c6448-6b07-4f49-bf47-85212ab58ed1',
      allowLocalhostAsSecureOrigin: true,
      serviceWorkerPath: '/push/onesignal/js/OneSignalSDKWorker.js'
    });

    OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
      if (isEnabled) {
        console.log('As notificações estão habilitadas!');
      } else {
        OneSignal.showNativePrompt().then(function () {
          console.log('Prompt mostrado');
        }).catch(function (error) {
          console.log('Erro ao mostrar o prompt:', error);
        });
      }
    }).catch(function (error) {
      console.log('Erro ao verificar o status das notificações:', error);
    });
  });
}
